html,
body {
    font-family:   "Segoe UI", "Helvetica Neue", Arial;
    max-width: 100%;
    overflow-x: hidden;
}
.font-link {
    font-family:   "Segoe UI", "Helvetica Neue", Arial;
}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.navTabs {
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 2.1;
    background-color: #0094d9;
    border-color: #0094d9;
    border-radius: 0px;
    height: 35px;
    width: 130px;
    margin: 1px;
    
}

.navTabs:hover {
    background-color: #005982;
    color: white;
    font-weight: bold;
}

.navTabsText {
    color: white;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
    
}

.navTabsText:hover {
    background-color: #005982;
    color: white;
    font-weight: bold;
}

#dropdownTabs {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
    background-color: #0094d9;
    border-color: #0094d9;
    border-radius: 0px;
    height: 35px;
    width: 115px;
    margin: 1px;
}

#dropdownTabs:hover {
    background-color: #005982;
    color: white;
    font-weight: bold;
    font-size: 14px;
    
}

.sideTabs {
    justify-content: center;
    align-items: center;
    text-align: center;
    
    line-height: 2.3;
    color: #0094d9;
    font-size: 14px;
    text-decoration: none;
    background-color: #e1e1e1;
    border-color: #e1e1e1;
    border-radius: 0px;
    height: 32px;
    width: 115px;
    /* height:5%;
 width: 80%; */
    margin: 1px;
   
}
.sideTabs:hover {
    background-color: #6d6d6d;
    color: white;
    font-weight: bold;
}

.tableElement-img {
    border: 1px solid;
}

.tableElement-img:hover {
    -webkit-filter: opacity(40%);
    -moz-filter: opacity(40%);
    -o-filter: opacity(40%);
    -ms-filter: opacity(40%);
    filter: opacity(40%);
}


.h1 {   
    text-align: left;
    color: #000000;
    font-size: 20pt !important;
   
    font-weight: bold;
}
.h2 {   
    text-align: left;
    color: #4471aa;
    font-size: 16pt !important;
   
    font-weight: bold;
}
.h3 {
    color: #000000;
    font-size: 14pt !important;
    font-weight: bold;
}


.btn-sm {
  padding: 2px 6px;
  font-size: 12x;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    text-align: center;
  }

  .btn-success:focus,
  .btn-success.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
  }

  .text-xsm
  {
    font-size: 13px;
  }


  .image_button {
    border: 1px solid rgb(200, 200, 200);
    border-style: solid;
    border-width: 1px;
    padding: 8px;
    vertical-align: middle;
    text-align: center;
}

.image_button:hover {
    border: 1px solid rgb(200, 200, 200);
    border-style: solid;
    border-width: 1px 2px 2px 1px;
    padding: 8px;
    vertical-align: middle;
    text-align: center;
}

  .modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    font-size: 13px;
  }

 
.responsive-container {
    width: 500px;
    height: 300px;
}

.responsive-container-xs {
    width: 500px;
    height: 100px;
}

.blur-tooltip {
    /* Add the blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.tooltip-text {
    color: black;
    font-family: Montserrat;
    font-weight: 30;
    border: 1px solid;
    border-radius: 0.75rem;
    padding: 1rem;
    text-align: center;
    background-color: #f5f5dc;
}

.cal_modal_style .modal-title{
    font-size: 18px ;
    font-style: italic;

}

.cal_modal_style .modal-header{
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    
}

.cal_modal_style .modal-body{
    position: relative;
    width: auto;
    margin: 10px;
    font-size: 14px ;
}

.cal_modal_style .form-control{
    font-size: 14px;
}

.cal_modal_style .form-select{
    font-size: 14px;
}


