body {
    margin: 0;
    font-family: "Trebuchet MS", "sans-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Trebuchet MS", "sans-serif";
}
